<template>
  <div ref="current">
    <el-autocomplete
      class="inline-input"
      v-model="modelvalue"
      :fetch-suggestions="querySearchAsync"
      placeholder="请输入内容"
      value-key="parkName"
      :trigger-on-focus="false"
      @select="handleSelect"
      style="width:100%"
    ></el-autocomplete>
  </div>
</template>
<script>
export default {
  data() {
    return {
      className: "my_auto_complete",
      modelvalue: "",
      restaurants: [],
      parkList: [],
      isScroll: true,
      page: 1,
      pageSize: 20,
    };
  },
  props: {
    slaveRelations: {
      default: "0,1,2",
    },
    parkType: {
      default: "",
    },
    areaIds: {
      default: "",
    },
    dataSource: {
      default: '',
    },
    operationId: {
      default: '',
    },
  },
  watch: {
    modelvalue(newVal) {
      if (!newVal) {
        this.$emit("valueChange", "");
        this.$emit("dataChange", {});
      }
    },
  },
  methods: {
    setShowVal(val) {
      this.modelvalue = val;
    },
    setValue() {
      this.modelvalue = "";
      this.$emit("valueChange", "");
    },
    querySearchAsync(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/bacb/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: queryString,
            dataSource: this.dataSource,
            slaveRelations: this.slaveRelations,
            parkType: this.parkType,
            areaIds: this.areaIds,
            operationId: this.operationId
          },
        })
        .then((res) => {
          if (res.state == 0) {
            if (res.value && res.value.length) {
              this.$emit("dataChange", res.value[0]);
              cb(res.value);
            } else {
              this.$emit("dataChange", { parkName: "", parkId: "" });
              cb([]);
            }
            this.$emit("valueChange", "");
          } else {
            cb([]);
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleSelect(item) {
      this.$emit("valueChange", item.parkId);
      // this.$emit('valueChange', item.parkName);
    },
  },
  mounted() {},
};
</script>

